import React, {Component} from "react";
import {Avatar, Button, Card, Col, Form, Icon, Input, Row} from "antd";
import {doSignInWithEmailAndPassword} from "../../firebase/auth"
import withAuthorization from "../../session/withAuthorization";
import firebaseAuthenticationErrors from "../../firebase/firebaseAuthenticationErrors";
import {trim} from "lodash";

const FormItem = Form.Item;

const login = Form.create()(
    class Login extends Component {

        state = {
            password: "",
            email: "",
            loginSpin: false,
        };

        login = (event) => {
            event.preventDefault();

            this.setState({
                email: trim(this.state.email),
                loginSpin: true
            }, () => {
                doSignInWithEmailAndPassword(this.state.email, this.state.password,
                    null,
                    error => {
                        this.setState({
                            errorMessage: firebaseAuthenticationErrors[error.code],
                            loginSpin: false
                        })
                    });
            });
        };

        render() {
            const {errorMessage, loginSpin} = this.state;

            return (
                <div className="login-content">
                    <Row>
                        <Col>
                            <Form onSubmit={this.login}
                                  className="login-form">
                                <Card className="login-card">
                                    <div style={{textAlign: "center"}}>
                                        <FormItem style={{marginBottom: "20px"}}>
                                            <Avatar size={100}
                                                    style={{backgroundColor: '#1890ff'}}
                                                    icon="team"/>
                                        </FormItem>
                                    </div>
                                    <h3 style={{color: "red"}}>{errorMessage}</h3>
                                    <FormItem style={{minWidth: "100%", marginBottom: "20px"}}
                                              validateStatus={errorMessage ? "error" : ""}
                                              required>
                                        <Input prefix={<Icon type="user"
                                                             style={{color: 'rgba(0,0,0,.25)'}}/>}
                                               name="email"
                                               onChange={(event) => this.setState({
                                                   email: event.target.value,
                                                   errorMessage: null
                                               })}
                                               autoComplete="off"
                                               placeholder="Enter your email"/>
                                    </FormItem>
                                    <FormItem style={{minWidth: "100%", marginBottom: "20px"}}
                                              validateStatus={errorMessage ? "error" : ""}
                                              required>
                                        <Input.Password prefix={<Icon type="lock"
                                                                      style={{color: 'rgba(0,0,0,.25)'}}/>}
                                                        id="password"
                                                        name="password"
                                                        type={"password"}
                                                        onChange={(event) => this.setState({
                                                            password: event.target.value,
                                                            errorMessage: null
                                                        })}
                                                        autoComplete="off"
                                                        placeholder="Enter your Password"/>
                                    </FormItem>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        loading={loginSpin}
                                        disabled={errorMessage}
                                        className="login-form-button"
                                    >LOGIN</Button>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </div>
            );
        }
    }
);
const condition = authUser => !authUser;
const redirect = authUser => authUser.roleCode === "admin" ? "/users" : "/reservations";
export default withAuthorization(condition, redirect)(login);