import React, {Component} from "react";
import {Button, Divider, Form, Input, List, message, Modal, Select, Spin} from "antd";
import {antIcon} from "../../utils";
import moment from "moment";
import UrlAssembler from "url-assembler";
import {putTravelerUrl} from "../../firebase/config";
import countryList from "country-list";

const {Option} = Select;

export default Form.create()(
    class SearchGuest extends Component {

        state = {
            loadingDialog: false,
            companyCode: "PERUHOP",
            loadingSearch: true,
            searchName: this.props.currentAction === "put" ? this.props.currentGuest.guestFirstName.split(" ")[0] : "",
        };

        componentDidMount() {
            this.fetchGuest();
        };

        fetchGuest = () => {

            const url = UrlAssembler(putTravelerUrl)
                .param("companyCode", this.state.companyCode)
                .param("searchName", this.state.searchName)
                .toString();

            fetch(url, {method: "GET"})
                .then(res => res.json())
                .catch(error => console.log(error))
                .then(res => this.setState({guestList: res.data, loadingSearch: false}, () => {
                }));
        };

        clone = () => {
            const data = {
                companyCode: this.props.currentUser.company.code.toUpperCase(),
                startDate: moment().format("YYYY-MM-DD")
            };
            const url = putTravelerUrl + this.state.traveler.id + "/clone";

            fetch(url,
                {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                })
                .then(response => {
                    return response;
                })
                .catch(error => {
                    return error;
                });

        };

        addGuest = (currentGuest, customFieldName) => {
            this.setState({loadingDialog: true}, () => {

                const putTravelerPromise = this.clone();

                const url = UrlAssembler("https://cloudbeds.peruhop.com/postGuest")
                    .param("hostel", this.props.currentUser.company.code)
                    .toString();

                const data = this.formData(currentGuest, customFieldName);

                const fetchPromise = fetch(url, {
                    method: "POST",
                    body: data
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(response.statusText);
                    })
                    .then(responseJSON => {
                        if (!responseJSON.success) {
                            throw Error(responseJSON.message);
                        }
                    });

                Promise.all([putTravelerPromise, fetchPromise])
                    .then((event) => this.notificationMessageSuccess("Update success"))
                    .catch(error => this.notificationMessageError("An error occurred, please retry later..." + error));

            });
        };

        updateGuest = (currentGuest, customFieldName) => {
            this.setState({loadingDialog: true}, () => {

                const putTravelerPromise = this.clone();

                const url = UrlAssembler("https://cloudbeds.peruhop.com/putGuest")
                    .param("hostel", this.props.currentUser.company.code)
                    .toString();

                const data = this.formData(currentGuest, customFieldName);

                const fetchPromise = fetch(url, {
                    method: "PUT",
                    body: data
                })
                    .then(response => {

                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(response.statusText);
                    })
                    .then(responseJSON => {
                        if (!responseJSON.success) {
                            throw Error(responseJSON.message);
                        }
                    });

                Promise.all([putTravelerPromise, fetchPromise])
                    .then((event) => this.notificationMessageSuccess("Update success"))
                    .catch(error => this.notificationMessageError("An error occurred, please retry later..." + error));

            });
        };

        notificationMessageError = (messageError) => {
            message.error(messageError);
            this.props.closeGuestFormDialog();
            this.props.fetchReservations();
        };

        notificationMessageSuccess = (messageSuccess) => {
            message.success(messageSuccess);
            this.props.closeGuestFormDialog();
            this.props.fetchReservations();
        };

        formData = (guestInfo, customFieldName) => {
            const GUEST_PREVIOUS_CITY = "guestPreviousCity";
            const GUEST_PROFESSION = "guestProfession";

            return Object.keys(guestInfo).reduce((acc, key) => {

                if (this.props.currentUser.enableArriveFrom && key === GUEST_PREVIOUS_CITY) {
                    acc.append(`${customFieldName}[0][customFieldName]`, GUEST_PREVIOUS_CITY);
                    acc.append(`${customFieldName}[0][customFieldValue]`, guestInfo[key]);

                    return acc;
                } else if (this.props.currentUser.enableProfession && key === GUEST_PROFESSION) {
                    acc.append(`${customFieldName}[1][customFieldName]`, GUEST_PROFESSION);
                    acc.append(`${customFieldName}[1][customFieldValue]`, guestInfo[key]);

                    return acc;
                }

                acc.append(key, guestInfo[key]);

                return acc;
            }, new FormData());
        };

        stateToGuest = () => {
            let guest = {
                guestFirstName: this.state.traveler.firstName,
                guestLastName: this.state.traveler.lastName,
                guestEmail: this.props.currentGuest.guestEmail,
                guestDocumentNumber: this.state.traveler.document,
                guestID: this.props.currentGuest.guestID,
                guestDocumentType: "passport",
                guestGender: this.state.traveler.gender,
                guestBirthDate: moment().subtract(this.state.traveler.age, "year").format("YYYY-MM-DD"),
                guestCountry: this.state.traveler.country,
                guestDocumentIssuingCountry: this.state.traveler.country,
                guestDocumentExpirationDate: "",
                guestDocumentIssueDate: "",
                guestPreviousCity: "PERU HOP",
                guestProfession: "student"
            };

            if (this.props.currentAction === "put") {
                guest.guestID = this.props.currentGuest.guestID;
            } else {
                guest.reservationID = this.props.currentReservation.reservationID;
            }
            return guest;
        };

        confirmDialog = () => {
            Modal.confirm({
                title: "Are you sure you want to exit?",
                okText: "YES",
                okType: "warning",
                cancelText: "NO",
                onOk: () => {
                    this.props.closeGuestFormDialog()
                }
            });
        };

        confirmClone = () => {
            Modal.confirm({
                title: "Are you sure you want clone?",
                okText: "YES",
                okType: "warning",
                cancelText: "CANCEL",
                onOk: () => {
                    this.props.currentAction === "put"
                        ? this.updateGuest(this.stateToGuest(), "guestCustomFields")
                        : this.addGuest(this.stateToGuest(), "customFields")
                }
            });
        };

        render() {

            return (
                <Modal
                    className="modalForm"
                    title={
                        this.props.currentAction === "put"
                            ? `Update ${this.props.currentGuest.guestFirstName}'s info`
                            : `Add ${this.props.currentGuest.guestFirstName}'s Guest`
                    }
                    visible={this.props.guestFormDialogOpened}
                    onCancel={this.confirmDialog}
                    footer={null}>
                    {
                        this.state.loadingDialog ? (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                <Spin indicator={antIcon}
                                      spinning={true}
                                      className="spin-version">
                                </Spin>
                            </div>
                        ) : (
                            <div>
                                <Form.Item>
                                    <Input placeholder={"Name, Last Name or Document "}
                                           defaultValue={this.state.searchName}
                                           onChange={event => this.setState({searchName: event.target.value})}/>
                                </Form.Item>
                                <Form.Item>
                                    <Select placeholder="Select a company"
                                            style={{width: "100%"}}
                                            defaultValue={this.state.companyCode}
                                            onChange={value => this.setState({companyCode: value})}>
                                        <Option key={"PERUHOP"}
                                                value={"PERUHOP"}>
                                            PERU HOP
                                        </Option>
                                        <Option key={"BOLIVIAHOP"}
                                                value={"BOLIVIAHOP"}>
                                            BOLIVIA HOP
                                        </Option>
                                        <Option key={"ECUADORHOP"}
                                                value={"ECUADORHOP"}>
                                            ECUADOR HOP
                                        </Option>
                                        <Option key={"RAINBOWMOUNTAINTRAVELS"}
                                                value={"RAINBOWMOUNTAINTRAVELS"}>
                                            RAINBOW MOUNTAIN TRAVELS
                                        </Option>
                                        <Option key={"WILDROVERAREQUIPA"}
                                                value={"WILDROVERAREQUIPA"}>
                                            WILD ROVER AREQUIPA
                                        </Option>
                                        <Option key={"WILDROVERCUSCO"}
                                                value={"WILDROVERCUSCO"}>
                                            WILD ROVER CUSCO
                                        </Option>
                                        <Option key={"WILDROVERHUACACHINA"}
                                                value={"WILDROVERHUACACHINA"}>
                                            WILD ROVER HUACACHNA
                                        </Option>
                                        <Option key={"WILDROVERLAPAZ"}
                                                value={"WILDROVERLAPAZ"}>
                                            WILD ROVER LA PAZ
                                        </Option>
                                        <Option key={"WILDROVERMANCORA"}
                                                value={"WILDROVERMANCORA"}>
                                            WILD ROVER MANCORA
                                        </Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary"
                                            className="margin-5"
                                            loading={this.state.loadingSearch}
                                            onClick={() => this.setState({loadingSearch: true}, () => this.fetchGuest())}
                                            icon="search">
                                        SEARCH
                                    </Button>
                                </Form.Item>
                                <Divider/>
                                <List
                                    itemLayout="vertical"
                                    size="large"
                                    dataSource={this.state.guestList}
                                    renderItem={guest => (
                                        <List.Item style={{cursor: "pointer", display: "flex"}}
                                                   actions={
                                                       [
                                                           <Button type={"primary"}
                                                                   onClick={() => this.setState({traveler: guest}, () => this.confirmClone())}
                                                                   size={"small"}>
                                                               CLONE
                                                           </Button>
                                                       ]}>
                                            <div className="list-search-guest">
                                                <h3>
                                                <span>
                                                    {moment(guest.startDate).format("DD/MM/YYYY")}
                                                </span>
                                                    <div>
                                                        {guest.firstName + ", " + guest.lastName}
                                                        <span>
                                                        {" [" + countryList.getName(guest.country) + "]"}
                                                    </span>
                                                    </div>
                                                    <span>
                                                    {guest.companyCode}
                                                </span>
                                                </h3>
                                            </div>
                                        </List.Item>
                                    )}>
                                </List>
                            </div>
                        )}
                </Modal>);
        }
    }
);
