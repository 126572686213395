import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import {createDocument, updateDocument} from "../../firebase";

const FormItem = Form.Item;

const newCompany = {
    id: "",
    name: "",
    code: "",
};

export default Form.create()(
    class Company extends Component {

        state = {
            id: this.props.match.params.companyId,
        };

        componentWillMount() {
            this.fetchCompanies();
        }

        fetchCompanies = () => {
            const {companies} = this.props;
            if (companies) {
                const currentCompany = companies.find(company => company.id === this.state.id);

                if (this.state.id === "new") {
                    this.companyToState(newCompany)
                } else if (currentCompany) {
                    this.companyToState(currentCompany);
                } else {
                    this.props.history.push("/notFound")
                }
            } else this.props.history.push("/companies")
        };

        companyToState = (company) => {
            this.setState({
                id: company.id,
                name: company.name,
                code: company.code,
            });
        };

        stateToCompany = () => {
            return {
                name: this.state.name,
                code: this.state.code,
            }
        };

        saveCompany = (event) => {
            event.preventDefault();
            this.props.form.validateFields((err) => {
                if (!err) {

                    !this.state.id
                        ? this.addCompany()
                        : this.editCompany()
                }
            });
        };

        addCompany = () => createDocument("companies", this.stateToCompany()).then(() => {
            message.success("Company Created")
            this.props.history.push("/companies");
        });

        editCompany = () => updateDocument("companies", this.state.id, this.stateToCompany()).then(() => {
            message.success("Company Updated");
            this.props.history.push("/companies");
        });

        render() {
            const {getFieldDecorator} = this.props.form;
            return (
                <Form onSubmit={this.saveCompany}>
                    <FormItem label="Company name">
                        {getFieldDecorator("companyName", {
                            initialValue: this.state.name,
                            rules: [{required: true, message: "Company name is required"}],
                        })(
                            <Input
                                placeholder="Company name"
                                onChange={(event) => this.setState({name: event.target.value})}/>
                        )}
                    </FormItem>
                    <FormItem label="Company code">
                        {getFieldDecorator("companyCode", {
                            initialValue: this.state.code,
                            rules: [{required: true, message: "Company code is required"}],
                        })(
                            <Input
                                placeholder="Company code"
                                onChange={(event) => this.setState({code: event.target.value})}/>
                        )}
                    </FormItem>
                    <div style={{textAlign: "right"}}>
                        <Button
                            className="margin-5"
                            type="default"
                            onClick={() => this.props.history.push("/companies")}>
                            CANCEL
                        </Button>
                        <Button
                            className="margin-5"
                            type="primary"
                            htmlType="submit">
                            SAVE
                        </Button>
                    </div>
                </Form>
            );
        }
    }
)
