import React from 'react';
import {withRouter} from 'react-router-dom';
import AuthUserContext from './AuthUserContext';

const withAuthorization = (condition, redirect) => (Component) => {

    class WithAuthorization extends React.Component {

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        condition(authUser) ?
                            <Component {...this.props}/>
                            : this.props.history.push(redirect(authUser))
                    }
                </AuthUserContext.Consumer>
            );
        }
    }

    return withRouter(WithAuthorization);
};

export default withAuthorization;