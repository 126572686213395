import React, {Component} from "react";
import {Col, Input, message, Row} from "antd";
import moment from "moment";
import {spinLoaderFixed, withoutAccents} from "../../utils";
import Reservation from "../../components/reservations/Reservation";
import Guest from "../../components/reservations/Guest";
import SearchGuest from "../../components/reservations/SearchGuest";
import UrlAssembler from "url-assembler";
import withAuthorization from "../../session/withAuthorization";

const Search = Input.Search;

const reservations = class Reservations extends Component {

    state = {
        reservations: [],
        error: "",
        search: "",
        guestFormDialogOpened: false,
        loadingReservations: true,
    };

    componentDidMount() {
        this.fetchReservations();
    };

    fetchReservations = (pageNumber = 1) => {
        this.setState({
            loadingReservations: true,
            search: "",
            reservations: [],
        }, () => {
            const searchURL = UrlAssembler("https://cloudbeds.peruhop.com/getReservations")
                .param("hostel", this.props.currentUser.company.code)
                .param("status", "confirmed,checked_in")
                .param("includeGuestsDetails", true)
                .param("checkInFrom", moment().subtract(1, "days").format("YYYY-MM-DD"))
                .param("checkInTo", moment().add(1, "days").format("YYYY-MM-DD"))
                .param("pageNumber", pageNumber)
                .toString();

            fetch(searchURL, {method: "GET"})
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error(response.statusText);
                })
                .then(responseJson => {
                    this.state.reservations.push(...responseJson.data);

                    if (responseJson.data.total > this.state.reservations.length) {
                        this.fetchReservations((pageNumber += 1));
                    } else {
                        this.setState({loadingReservations: false});
                    }
                })
                .catch(error => {
                    console.log("->", error);
                    message.error("Something went wrong, try again");
                    this.setState({
                        reservations: [],
                        error: "Something went wrong, try again",
                        loadingReservations: false
                    });
                });
        });
    };

    setCurrentGuest = guest => this.setState({currentGuest: guest});

    setCurrentReservation = reservation => this.setState({currentReservation: reservation});

    setCurrentAction = action => this.setState({currentAction: action});

    filteredReservations() {
        return this.state.reservations.filter(reservation => withoutAccents(reservation.guestName.toLowerCase()).indexOf(withoutAccents(this.state.search.trim().toLowerCase())) !== -1);
    }

    openGuestFormDialog = () => this.setState({guestFormDialogOpened: true});

    closeGuestFormDialog = () => this.setState({guestFormDialogOpened: false}, () => window.scrollTo(0, 0));

    render() {

        return (
            <div>
                {this.state.loadingReservations ? (
                    spinLoaderFixed()
                ) : (
                    <div>
                        <Row type="flex"
                             justify="space-around"
                             align="middle">
                            <Col xs={24}
                                 sm={18}
                                 md={15}
                                 lg={15}
                                 xl={15}
                                 style={{textAlign: "center"}}>
                                <Search
                                    value={this.state.search}
                                    onChange={e => this.setState({search: e.target.value})}
                                    placeholder="Input guest name"
                                    enterButton="REFRESH"
                                    size="large"
                                    onSearch={() => this.fetchReservations()}/>
                            </Col>
                            {
                                this.filteredReservations().map(currentReservation => (
                                    <Reservation
                                        key={currentReservation.reservationID}
                                        setCurrentAction={this.setCurrentAction}
                                        companies={this.props.companies}
                                        currentUser={this.props.currentUser}
                                        currentReservation={currentReservation}
                                        setCurrentReservation={this.setCurrentReservation}
                                        setCurrentGuest={this.setCurrentGuest}
                                        openGuestFormDialog={this.openGuestFormDialog}/>
                                ))
                            }
                            {
                                (this.state.guestFormDialogOpened && this.props.currentUser.roleCode === "user")
                                    ? <Guest
                                        fetchReservations={this.fetchReservations}
                                        currentAction={this.state.currentAction}
                                        companies={this.props.companies}
                                        currentUser={this.props.currentUser}
                                        reservations={this.state.reservations}
                                        currentGuest={this.state.currentGuest}
                                        currentReservation={this.state.currentReservation}
                                        guestFormDialogOpened={this.state.guestFormDialogOpened}
                                        closeGuestFormDialog={this.closeGuestFormDialog}/>
                                    : (this.state.guestFormDialogOpened && this.props.currentUser.roleCode === "receptionist") &&
                                    <SearchGuest
                                        fetchReservations={this.fetchReservations}
                                        currentAction={this.state.currentAction}
                                        companies={this.props.companies}
                                        currentUser={this.props.currentUser}
                                        reservations={this.state.reservations}
                                        currentGuest={this.state.currentGuest}
                                        currentReservation={this.state.currentReservation}
                                        guestFormDialogOpened={this.state.guestFormDialogOpened}
                                        closeGuestFormDialog={this.closeGuestFormDialog}/>
                            }
                        </Row>
                    </div>
                )}
            </div>
        )
    }
};

const condition = authUser => authUser && authUser.roleCode !== "admin";
const redirect = () => "/users";
export default withAuthorization(condition, redirect)(reservations);
