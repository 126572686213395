import React from "react";
import {Icon, Spin} from "antd";

export const antIcon = <Icon type="loading"
                             className="spin-version-icon"
                             spin/>;

export const spinLoader = (message) => {
    return (
        <div className="spin-loader">
            <Spin indicator={antIcon}
                  tip={message}
                  spinning={true}
                  className="spin-version">
            </Spin>
        </div>
    )
};

export const spinLoaderFixed = (message) => {
    return (
        <div className="spin-loader-fixed">
            <Spin indicator={antIcon}
                  tip={message}
                  spinning={true}
                  className="spin-version">
            </Spin>
        </div>
    )
};
