import React, {Component} from "react";
import {Route, Switch} from "react-router-dom";
import {Redirect} from "react-router";
import {Login, Reservations, Users} from "../pages";
import {BaseLayout, CompanyForm, ReservationForm, UserForm} from "../components";
import PrivateRoute from "./PrivateRoute";
import Companies from "../pages/companies";
import NotFound from "../pages/404/NotFound";

export default class Routes extends Component {

    render() {

        return (
            <Switch>
                <Route exact
                       path="/login"
                       render={props => <Login {...props}
                                               {...this.props} />}/>
                <PrivateRoute exact
                              path="/reservations">
                    <BaseLayout {...this.props}>
                        <Reservations/>
                    </BaseLayout>
                </PrivateRoute>
                <PrivateRoute exact
                              path="/reservations/:reservationID">
                    <BaseLayout {...this.props}>
                        <ReservationForm/>
                    </BaseLayout>
                </PrivateRoute>
                <PrivateRoute exact
                              path="/users">
                    <BaseLayout {...this.props}>
                        <Users/>
                    </BaseLayout>
                </PrivateRoute>
                <PrivateRoute exact
                              path="/companies">
                    <BaseLayout {...this.props}>
                        <Companies/>
                    </BaseLayout>
                </PrivateRoute>
                <PrivateRoute exact
                              path="/companies/:companyId">
                    <BaseLayout {...this.props}>
                        <CompanyForm/>
                    </BaseLayout>
                </PrivateRoute>
                <PrivateRoute exact
                              path="/users/:userId">
                    <BaseLayout {...this.props}>
                        <UserForm/>
                    </BaseLayout>
                </PrivateRoute>
                <Route path="/notFound"
                       component={NotFound}/>
                <Redirect to="/reservations"/>
            </Switch>
        )
    }
}