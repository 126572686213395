import React from "react";

const uppercase = string => `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`;

const humanize = string => uppercase(string).replace("_", " ");

const initials = fullName => {
    const parts = fullName.split(" ");

    if (parts.length <= 2) {
        return parts.map(name => name[0].toUpperCase()).join("");
    }

    const firstInitial = initials(parts[0]);
    const secondInitial = initials(parts[parts.length - 1]);
    return `${firstInitial}${secondInitial}`;
};

const withoutAccents = str => str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

export {uppercase, initials, withoutAccents, humanize};
