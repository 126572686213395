import React from 'react';
import AuthUserContext from './AuthUserContext';
import firebase from "@firebase/app";

const withAuthentication = (Component) =>
    class WithAuthentication extends React.Component {
        state = {
            authUser: null,
            loadingUser: true
        };

        componentDidMount() {
            firebase.auth().onAuthStateChanged(authUser => {
                authUser
                    ? this.fetchUser(authUser)
                    : this.setState(() => ({
                        authUser: null,
                        loadingUser: false,
                    }));
            });
        };

        userToState = (user) => {
            this.setState({
                authUser: user,
                loadingUser: false
            });
        };

        fetchUserCompany = (user) => {
            if (user.companyId) {
                firebase.firestore().collection("companies").doc(user.companyId).get()
                    .then(doc => {
                        user.company = doc.exists
                            ? {
                                ...doc.data(),
                                id: doc.id,
                            }
                            : {};
                        this.userToState(user);
                    });
            } else {
                this.userToState(user);
            }
        };

        fetchUser = (authUser) => {
            firebase.firestore().collection("users").doc(authUser.uid).get()
                .then(doc => {
                    const user = doc.data();
                    user.id = authUser.uid;

                    this.fetchUserCompany(user);
                });
        };

        render() {

            const {authUser} = this.state;

            return (
                <AuthUserContext.Provider value={authUser}>
                    <Component loadingUser={this.state.loadingUser}
                               currentUser={this.state.authUser}
                               version={this.props.version}/>
                </AuthUserContext.Provider>
            );
        }
    };

export default withAuthentication;