import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/auth";

const hostName = window.location.hostname;

const productionConfig = {
    apiKey: "AIzaSyC2kLVgZax-nz9y1-cUqkyV2cpoFbvwK_w",
    authDomain: "cloudbeds-checkin.firebaseapp.com",
    databaseURL: "https://cloudbeds-checkin.firebaseio.com",
    projectId: "cloudbeds-checkin",
    storageBucket: "cloudbeds-checkin.appspot.com",
    messagingSenderId: "831245767714"
};

const developmentConfig = {
    apiKey: "AIzaSyAtKq4dL7G41P7_lY9boNzIc1oKyHX7AUU",
    authDomain: "cloudbeds-checkin-dev.firebaseapp.com",
    databaseURL: "https://cloudbeds-checkin-dev.firebaseio.com",
    projectId: "cloudbeds-checkin-dev",
    storageBucket: "cloudbeds-checkin-dev.appspot.com",
    messagingSenderId: "667337123473"
};

let putTravelerUrl = "https://us-central1-peruhop-passports.cloudfunctions.net/travelers/";

if (hostName !== "localhost" && !hostName.includes("-dev")) {
    firebase.initializeApp(productionConfig);
} else {
    firebase.initializeApp(developmentConfig);
}

let pageLoaded = false;

firebase.firestore()
    .collection("versions")
    .onSnapshot(() => {
        pageLoaded && document.location.reload(true);
        pageLoaded = true;
    });

const firestore = firebase.firestore();
const storage = firebase.storage();
const auth = firebase.auth();

export {
    storage,
    firestore,
    auth,
    putTravelerUrl,
};