import React, {Component} from "react";
import {BrowserRouter} from "react-router-dom";
import {fetchCollection} from "./firebase/index"
import Routes from "./routes"
import withAuthentication from "./session/withAuthentication";
import {spinLoaderFixed} from "./utils/loader";

class App extends Component {

    state = {
        loadingVersion: false,
        loginSuccessFull: false,
        images: "https://firebasestorage.googleapis.com/v0/b/blank-project-225314.appspot.com/o/imageProfileDefault%2FimageProfile.jpeg?alt=media&token=2edea679-f0c6-4bcf-b77e-a49c16c2a87e",
        test: "",
    };

    componentDidUpdate(prevProps) {
        if (this.props.currentUser && prevProps.currentUser === null)
            this.fetchData();
    };

    fetchData = () => {
        this.fetchUsers();
        this.fetchCompanies();
    };

    fetchUsers = () => fetchCollection("users", (data) => this.setState({users: data}));

    fetchCompanies = () => fetchCollection("companies", (data) => this.setState({companies: data}));

    session = (loginSuccessFull) => this.setState({loginSuccessFull: loginSuccessFull});

    render() {

        if (this.props.loadingUser) return spinLoaderFixed("");

        return (
            <BrowserRouter>
                <div>
                    <Routes {...this.state}
                            {...this.props}
                            session={this.session}/>
                </div>
            </BrowserRouter>
        );
    }
}

export default withAuthentication(App);
