import React, {Component} from "react";
import {Button, Checkbox, Form, Icon, Input, message, Radio, Select} from "antd";
import {isEmpty, map} from "lodash";
import {auth, createDocument, setDocument} from "../../firebase";

const FormItem = Form.Item;
const {Option} = Select;
const RadioGroup = Radio.Group;

const newUser = {
    id: "",
    companyId: undefined,
    email: "",
    password: "",
    createDate: "",
    roleCode: "admin",
    enableProfession: false,
    enableArriveFrom: false,
};

export default Form.create()(
    class user extends Component {

        state = {
            id: this.props.match.params.userId,
            roleCode: "admin",
            enableProfession: false,
            enableArriveFrom: false,
        };

        componentWillMount() {
            this.fetchUsers();
        };

        fetchUsers = () => {
            const {users} = this.props;
            const userId = this.props.match.params.userId;
            if (users) {
                const currentUser = users.find(item => item.id === userId);

                if (userId === "new") {
                    this.userToState(newUser)
                } else if (currentUser) {
                    this.userToState(currentUser);
                } else {
                    this.props.history.push("/NotFound")
                }
            } else this.props.history.push("/users")
        };

        userToState = (user) => {
            this.setState({
                id: user.id,
                companyId: user.companyId,
                email: user.email,
                password: user.password,
                createDate: user.createDate,
                roleCode: user.roleCode,
                enableProfession: user.enableProfession,
                enableArriveFrom: user.enableArriveFrom,
                currentUser: user
            });
        };

        stateToUser = () => {
            const currentUser = {
                companyId: this.state.companyId,
                email: this.state.email,
                password: this.state.password,
                roleCode: this.state.roleCode,
                enableProfession: this.state.enableProfession,
                enableArriveFrom: this.state.enableArriveFrom,
            };

            if (!this.state.id) {
                currentUser.createDate = new Date();
            }
            return currentUser;
        };

        createUser = (user) => createDocument("users", user).then(() => {
            message.success("Success");
            this.props.history.push("/users");
        });

        updateUser = (user) => setDocument("users", this.props.match.params.userId, user).then(() => {
                message.success("Update");
                this.props.history.push("/users");
            }
        );

        emailExists = (rule, email, callback) => {
            if (this.state.currentUser.email !== email) {
                auth.fetchSignInMethodsForEmail(this.state.email)
                    .then(providers => {
                        if (!isEmpty(providers)) {
                            callback("Email already exists.");
                            return
                        }
                        callback();
                    })
                    .catch(error => callback());
            } else {
                callback();
            }
        };

        saveUser = (event) => {
            event.preventDefault();
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.match.params.userId === "new"
                        ? this.createUser(this.stateToUser())
                        : this.updateUser(this.stateToUser())
                }
            });
        };

        render() {
            const {getFieldDecorator} = this.props.form;

            return (
                <div>
                    <div>
                        <Form onSubmit={this.saveUser}
                              autoComplete="off">
                            <div>
                                <FormItem label="Company: ">
                                    {getFieldDecorator("companyId", {
                                        initialValue: this.state.companyId,
                                        rules: [{required: true, message: "Company is required"}]
                                    })(
                                        <Select
                                            placeholder="Select a company"
                                            style={{width: "100%"}}
                                            onChange={value => this.setState({companyId: value})}>
                                            {
                                                map(this.props.companies, status => (
                                                    <Option key={status.id}
                                                            value={status.id}>
                                                        {status.name}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                    )}
                                </FormItem>
                                <FormItem label="Email: ">
                                    {getFieldDecorator("Email", {
                                        initialValue: this.state.email,
                                        validateTrigger: "onBlur",
                                        rules: [{validator: this.emailExists},
                                            {required: true, message: "Email is required."},
                                            {type: "email", message: "Wrong email format."}
                                        ]
                                    })(
                                        <Input
                                            type={"email"}
                                            autoComplete="new-email"
                                            placeholder="Email"
                                            onChange={(event) => this.setState({email: event.target.value})}/>
                                    )}
                                </FormItem>
                                <FormItem label="Password: ">
                                    {getFieldDecorator("Password", {
                                        initialValue: this.state.password,
                                        rules: [{required: true, message: "Password is required"}, {
                                            min: 6,
                                            message: "Min length"
                                        }]
                                    })(
                                        <Input.Password prefix={<Icon type="lock"
                                                                      style={{color: 'rgba(0,0,0,.25)'}}/>}
                                                        type="password"
                                                        autoComplete="new-password"
                                                        placeholder="Password"
                                                        onChange={(event) => this.setState({password: event.target.value})}/>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Checkbox
                                        checked={this.state.enableProfession}
                                        onChange={(e) => this.setState({enableProfession: e.target.checked})}>
                                        Enable profession
                                    </Checkbox>
                                    <br/>
                                    <Checkbox
                                        checked={this.state.enableArriveFrom}
                                        onChange={(e) => this.setState({enableArriveFrom: e.target.checked})}>
                                        Enable arrive from
                                    </Checkbox>
                                </FormItem>
                                <FormItem label="Role">
                                    {getFieldDecorator("roleCode", {
                                        initialValue: this.state.roleCode,
                                        rules: [{required: true, message: "is required"}]
                                    })(
                                        <RadioGroup
                                            style={{width: "100%"}}
                                            onChange={(e) => this.setState({roleCode: e.target.value})}>
                                            <Radio value={"admin"}>Admin</Radio>
                                            <Radio value={"user"}>User</Radio>
                                            <Radio value={"receptionist"}>Receptionist</Radio>
                                        </RadioGroup>
                                    )}
                                </FormItem>
                                <div style={{textAlign: "right"}}>
                                    <Button
                                        className="margin-5"
                                        type="secondary"
                                        onClick={() => this.props.history.push("/users")}>
                                        CANCEL
                                    </Button>
                                    <Button
                                        className="margin-5"
                                        disabled={this.state.buttonSaveStatus}
                                        type="primary"
                                        htmlType="submit">
                                        SAVE
                                    </Button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            )
        }
    }
)
