import React, {Component} from "react";
import {Button, Calendar, Form, Input, message, Modal, Radio, Select, Spin} from "antd";
import countryList from "country-list";
import {antIcon} from "../../utils";
import moment from "moment";
import UrlAssembler from "url-assembler";
import uuid from "uuid";
import {putTravelerUrl} from "../../firebase/config";

const FormItem = Form.Item;
const Option = Select.Option;
const RadioGroup = Radio.Group;

export default Form.create()(
    class Guest extends Component {

        profession = () => {
            if (!this.props.currentGuest.customFields) {
                return "";
            }

            let field = this.props.currentGuest.customFields.find(customField => customField.customFieldName === "Profession");

            if (!field) {
                return "";
            }

            return field.customFieldValue;
        };

        arrivingFrom = () => {
            if (!this.props.currentGuest.customFields) {
                return "";
            }

            let field = this.props.currentGuest.customFields.find(customField => customField.customFieldName === "Arriving From");

            if (!field) {
                return "";
            }

            return field.customFieldValue;
        };

        state = {
            guestFirstName: this.props.currentGuest.guestFirstName,
            guestLastName: this.props.currentGuest.guestLastName,
            guestEmail: this.props.currentGuest.guestEmail,
            guestCountry: this.props.currentGuest.guestCountry,
            guestGender: this.props.currentGuest.guestGender,
            guestBirthdate: this.props.currentGuest.guestBirthdate ? moment(this.props.currentGuest.guestBirthdate).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"),
            guestDocumentNumber: this.props.currentGuest.guestDocumentNumber,
            guestDocumentType: "passport",
            guestDocumentIssuingCountry: this.props.currentGuest.guestDocumentIssuingCountry,
            guestDocumentExpirationDate: "",
            guestDocumentIssueDate: "",
            loadingDialog: false,
            guestPreviousCity: this.arrivingFrom(),
            guestProfession: this.profession()
        };

        stateToGuest = () => {
            let guest = {
                guestFirstName: this.state.guestFirstName,
                guestLastName: this.state.guestLastName,
                guestEmail: this.state.guestEmail,
                guestCountry: this.state.guestCountry,
                guestGender: this.state.guestGender,
                guestBirthDate: this.state.guestBirthdate,
                guestDocumentNumber: this.state.guestDocumentNumber,
                guestDocumentType: this.state.guestDocumentType,
                guestDocumentIssuingCountry: this.state.guestDocumentIssuingCountry,
                guestDocumentExpirationDate: this.state.guestDocumentExpirationDate,
                guestDocumentIssueDate: this.state.guestDocumentIssueDate,
                guestPreviousCity: this.state.guestPreviousCity,
                guestProfession: this.state.guestProfession
            };

            if (this.props.currentAction === "put") {
                guest.guestID = this.props.currentGuest.guestID;
            } else {
                guest.reservationID = this.props.currentReservation.reservationID;
            }

            return guest;
        };

        confirmDialog = () => {
            Modal.confirm({
                title: "Are you sure you want to exit?",
                okText: "YES",
                okType: "warning",
                cancelText: "NO",
                onOk: () => {
                    this.props.closeGuestFormDialog()
                }
            });
        };

        saveGuest = (event) => {
            event.preventDefault();

            this.props.form.validateFields(err => {
                if (!err) {
                    this.props.currentAction === "put"
                        ? this.updateGuest(this.stateToGuest(), "guestCustomFields")
                        : this.addGuest(this.stateToGuest(), "customFields")
                }
            });
        };

        addGuest = (currentGuest, customFieldName) => {
            this.setState({loadingDialog: true}, () => {

                const putTravelerPromise = this.putTraveler(currentGuest);

                const url = UrlAssembler("https://cloudbeds.peruhop.com/postGuest")
                    .param("hostel", this.props.currentUser.company.code)
                    .toString();

                const data = this.formData(currentGuest, customFieldName);

                const fetchPromise = fetch(url, {
                    method: "POST",
                    body: data
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(response.statusText);
                    })
                    .catch(error => console.error("Error:", error));

                Promise.all([putTravelerPromise, fetchPromise])
                    .then((event) => this.notification(event));

            });
        };

        updateGuest = (currentGuest, customFieldName) => {
            this.setState({loadingDialog: true}, () => {

                const putTravelerPromise = this.putTraveler(currentGuest);

                const url = UrlAssembler("https://cloudbeds.peruhop.com/putGuest")
                    .param("hostel", this.props.currentUser.company.code)
                    .toString();

                const data = this.formData(currentGuest, customFieldName);

                const fetchPromise = fetch(url, {
                    method: "PUT",
                    body: data
                })
                    .then(response => {

                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error(response.statusText);
                    })
                    .catch(error => console.error("Error:", error));

                Promise.all([putTravelerPromise, fetchPromise])
                    .then((event) => this.notification(event));

            });
        };

        putTraveler = currentGuest => {
            const data = {
                "id": uuid(),
                "startDate": moment().format("YYYY-MM-DD"),
                "country": currentGuest.guestCountry,
                "firstName": currentGuest.guestFirstName,
                "lastName": currentGuest.guestLastName,
                "gender": currentGuest.guestGender,
                "document": currentGuest.guestDocumentNumber,
                "companyCode": this.props.currentUser.company.code,
                "age": moment().diff(moment(currentGuest.guestBirthDate), "years"),
            };
            return fetch(putTravelerUrl, {
                method: "PUT",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(response => {
                    return response
                });
        };

        notification = (response) => {
            (response[0].ok && response[1].success)
                ? message.success("THANK YOU! PLEASE SIGN IN YOUR CHECKIN FORM WITH THE RECEPTIONIST.", 10)
                : message.error("An error occured, please retry later...");
            this.props.closeGuestFormDialog();
            this.props.fetchReservations();
            this.setState({loadingDialog: false})
        };

        formData = (guestInfo, customFieldName) => {
            const GUEST_PREVIOUS_CITY = "guestPreviousCity";
            const GUEST_PROFESSION = "guestProfession";

            return Object.keys(guestInfo).reduce((acc, key) => {

                if (this.props.currentUser.enableArriveFrom && key === GUEST_PREVIOUS_CITY) {
                    acc.append(`${customFieldName}[0][customFieldName]`, GUEST_PREVIOUS_CITY);
                    acc.append(`${customFieldName}[0][customFieldValue]`, guestInfo[key]);

                    return acc;
                } else if (this.props.currentUser.enableProfession && key === GUEST_PROFESSION) {
                    acc.append(`${customFieldName}[1][customFieldName]`, GUEST_PROFESSION);
                    acc.append(`${customFieldName}[1][customFieldValue]`, guestInfo[key]);

                    return acc;
                }

                acc.append(key, guestInfo[key]);

                return acc;
            }, new FormData());
        };

        render() {

            const {getFieldDecorator} = this.props.form;

            return (
                <Modal
                    className="modalForm"
                    title={
                        this.props.currentAction === "put"
                            ? `Update ${this.props.currentGuest.guestFirstName}'s info`
                            : `Add ${this.props.currentGuest.guestFirstName}s Guest`
                    }
                    visible={this.props.guestFormDialogOpened}
                    onCancel={this.confirmDialog}
                    footer={null}>
                    {
                        this.state.loadingDialog ? (
                            <div
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                <Spin indicator={antIcon}
                                      spinning={true}
                                      className="spin-version">
                                </Spin>
                            </div>
                        ) : (
                            <Form onSubmit={this.saveGuest}>
                                <div>
                                    <FormItem label="First name">
                                        {getFieldDecorator("guestFirstName", {
                                            initialValue: this.state.guestFirstName,
                                            rules: [{required: true, message: "Fist Name is required."}],
                                        })(
                                            <Input
                                                placeholder="First Name"
                                                onChange={(value) => this.setState({guestFirstName: value.target.value})}/>
                                        )}
                                    </FormItem>
                                    <FormItem label="Last name">
                                        {getFieldDecorator("guestLastName", {
                                            initialValue: this.state.guestLastName,
                                            rules: [{required: true, message: "Last Name is required."}],
                                        })(
                                            <Input
                                                placeholder="Last Name"
                                                onChange={(value) => this.setState({guestLastName: value.target.value})}/>
                                        )}
                                    </FormItem>
                                    <FormItem label="Email address">
                                        {getFieldDecorator("guestEmail", {
                                            initialValue: this.state.guestEmail,
                                            rules: [{required: true, message: "Email is required."}],
                                        })(
                                            <Input
                                                placeholder="Email Address"
                                                onChange={(value) => this.setState({guestEmail: value.target.value})}/>
                                        )}
                                    </FormItem>
                                    <FormItem label="Gender">
                                        {getFieldDecorator("guestGender", {
                                            initialValue: this.state.guestGender,
                                            rules: [{required: true, message: "Gender is required."}],
                                        })(
                                            <RadioGroup
                                                onChange={event => this.setState({guestGender: event.target.value})}>
                                                <Radio value={"M"}>Male</Radio>
                                                <Radio value={"F"}>Female</Radio>
                                            </RadioGroup>
                                        )}
                                    </FormItem>
                                    <FormItem label="Date of birth">
                                        <div style={{width: 300, border: "1px solid #d9d9d9", borderRadius: 4}}>
                                            {getFieldDecorator("guestBirthdate", {
                                                initialValue: moment(this.state.guestBirthdate),
                                                rules: [{required: true, message: "guestBirthdate is required."}],
                                            })(
                                                <Calendar
                                                    validRange={[moment().subtract(150, "years"), moment()]}
                                                    fullscreen={false}
                                                    onChange={(value) => this.setState({guestBirthdate: moment(value).format("YYYY-MM-DD")})}/>
                                            )}
                                        </div>
                                    </FormItem>
                                    <FormItem label="Nationality">
                                        {getFieldDecorator("guestCountry", {
                                            initialValue: this.state.guestCountry,
                                            rules: [{required: true, message: "Nationality is required."}],
                                        })(
                                            <Select
                                                placeholder="Nationality"
                                                style={{width: "100%"}}
                                                onChange={event => {
                                                    this.setState({guestCountry: event});
                                                    this.setState({guestDocumentIssuingCountry: event});
                                                }}
                                                showSearch
                                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                                inputProps={{id: "guestCountry", name: "guestCountry"}}>

                                                {countryList.getCodes().map(code => (
                                                    <Option value={code.toUpperCase()}
                                                            key={code.toUpperCase()}>
                                                        {countryList.getName(code)}
                                                    </Option>
                                                ))}
                                            </Select>
                                        )}
                                    </FormItem>
                                    <FormItem label="Passport number">
                                        {getFieldDecorator("guestDocumentNumber", {
                                            initialValue: this.state.guestDocumentNumber,
                                            rules: [{required: true, message: "Passport is required."}],
                                        })(
                                            <Input
                                                id="guestDocumentNumber"
                                                placeholder="Passport Number"
                                                onChange={(value) => this.setState({guestDocumentNumber: value.target.value})}/>
                                        )}
                                    </FormItem>
                                    {
                                        this.props.currentUser.enableProfession &&
                                        <FormItem label="Profession">
                                            {getFieldDecorator("enableProfession", {
                                                initialValue: this.state.guestProfession,
                                                rules: [{required: true, message: "Profession is required."}],
                                            })(
                                                <Input
                                                    id="profession"
                                                    placeholder="Profession"
                                                    onChange={(value) => this.setState({guestProfession: value.target.value})}/>
                                            )}
                                        </FormItem>
                                    }
                                    {
                                        this.props.currentUser.enableArriveFrom &&
                                        <FormItem label="Arrive from">
                                            {getFieldDecorator("enableArriveFrom", {
                                                initialValue: this.state.guestPreviousCity,
                                                rules: [{required: true, message: "Arrive from is required."}],
                                            })(
                                                <Input
                                                    id="arriveFrom"
                                                    placeholder="Arrive from"
                                                    onChange={(value) => this.setState({guestPreviousCity: value.target.value})}/>
                                            )}
                                        </FormItem>
                                    }
                                    <FormItem style={{textAlign: "right"}}>
                                        <Button type="default"
                                                className="margin-5"
                                                onClick={() => this.confirmDialog()}>CANCEL</Button>
                                        <Button type={"primary"}
                                                className="margin-5"
                                                htmlType={"submit"}>OK</Button>
                                    </FormItem>
                                </div>
                            </Form>
                        )}
                </Modal>
            );
        }
    }
)
