import React from 'react';
import {fetchCollection} from "../firebase/index";
import {UpdateVersion} from "../components";
import App from "../App";
import {spinLoaderFixed} from "../utils/loader";

export default class CheckVersion extends React.Component {

    state = {
        version: "0.17",
        serverVersion: null,
    };

    componentDidMount() {
        fetchCollection("versions", (versions) => {
            const version = versions[0].refresh;
            this.setState({serverVersion: version});
        });
    };

    render() {
        if (this.state.serverVersion === null) return spinLoaderFixed("");

        return (
            <div>
                {
                    this.state.version !== this.state.serverVersion
                        ? <UpdateVersion className="login-content"/>
                        : <App version={this.state.serverVersion}/>
                }
            </div>
        );
    }
}
