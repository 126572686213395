import React, {Component} from "react";
import {Avatar, Button, Col, Collapse, Icon, Row, Spin} from "antd";
import {antIcon, humanize, initials} from "../../utils";
import {orderBy} from "lodash";
import UrlAssembler from "url-assembler";

const Panel = Collapse.Panel;

const newGuest = {
    guestFirstName: "",
    guestLastName: "",
    guestEmail: "",
    guestGender: "N/A",
    guestCountry: "",
    guestBirthdate: "",
    guestDocumentNumber: "",
    guestDocumentIssuingCountry: "",
};

export default class Reservation extends Component {

    state = {
        loadingReservation: false,
        canAddGuests: false,
        guestID: this.props.currentReservation.guestID,
        canGuestsNumber: 0,
        guestList: []
    };

    fetchReservation = reservationID => {
        this.setState({loadingReservation: true}, () => {
            const searchURL = UrlAssembler("https://cloudbeds.peruhop.com/getReservation")
                .param("hostel", this.props.currentUser.company.code)
                .param("reservationID", reservationID)
                .toString();

            fetch(searchURL, {method: "GET"})
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error(response.statusText);
                })
                .then(responseJson => {
                    const {guestList, assigned} = responseJson.data;
                    const guestCount = +Object.keys(guestList).length;
                    let roomsCount = 0;

                    assigned.forEach((room) => roomsCount += +room.adults);

                    this.setState({
                        loadingReservation: false,
                        canAddGuests: guestCount !== roomsCount,
                        canGuestsNumber: +roomsCount,
                        guestList: guestList,
                        currentGuest: guestList[this.state.guestID],
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        });
    };

    guestsToAdd = () => {
        let guests = [];
        const {guestList, canGuestsNumber} = this.state;
        const guestListSorted = orderBy(guestList, [(guest) => guest.guestID === this.props.currentReservation.guestID], ["desc"]);
        const guestIdList = Object.keys(guestListSorted);

        for (let i = 0; i < canGuestsNumber; i++) {
            guestListSorted[guestIdList[i]] ?
                guests.push(<Button type="dashed"
                                    className="btn-add-guest"
                                    key={`guests-to-add-${i}`}
                                    onClick={() => {
                                        this.props.setCurrentReservation(this.props.currentReservation);
                                        this.props.setCurrentGuest(guestListSorted[guestIdList[i]]);
                                        this.props.setCurrentAction("put");
                                        this.props.openGuestFormDialog();
                                    }}>
                    <span>
                        {
                            guestListSorted[guestIdList[i]].guestFirstName} {guestListSorted[guestIdList[i]].guestLastName
                    }
                        {
                            this.props.currentReservation.guestID === guestListSorted[guestIdList[i]].guestID &&
                            " (Owner)"
                        }
                    </span>
                </Button>)
                : guests.push(<Button type="dashed"
                                      className="btn-add-guest"
                                      key={`guests-to-add-${i}`}
                                      onClick={() => {
                                          this.props.setCurrentReservation(this.props.currentReservation);
                                          this.props.setCurrentGuest(newGuest);
                                          this.props.setCurrentAction("post");
                                          this.props.openGuestFormDialog();
                                      }}>
                    Add Guests
                    <Icon type="plus"/>
                </Button>)
        }
        return guests;
    };

    render() {

        return (
            <Col xs={24}
                 sm={18}
                 md={15}
                 lg={15}
                 xl={15}
                 style={{padding: "10px"}}
                 key={this.props.currentReservation.reservationID}>
                <Collapse onChange={e => this.fetchReservation(this.props.currentReservation.reservationID)}>
                    <Panel
                        header={
                            <div style={{paddingTop: "25px", paddingBottom: "25px"}}>
                                <Avatar size="large"
                                        style={{float: "left"}}>{initials(this.props.currentReservation.guestName)}</Avatar>
                                <div style={{marginLeft: "50px", fontSize: "18px"}}>
                                    <p>
                                        {humanize(this.props.currentReservation.guestName).toUpperCase()}
                                        <br/>
                                        <span style={{
                                            fontSize: "12px",
                                            color: "gray"
                                        }}>RESERVATION ID: {this.props.currentReservation.reservationID}</span><br/>
                                        <span style={{
                                            fontSize: "12px",
                                            color: "gray"
                                        }}>STATUS: {this.props.currentReservation.status}</span>
                                    </p>
                                </div>
                            </div>
                        }>
                        {this.state.loadingReservation ? (
                            <div
                                style={{
                                    width: "100%",
                                    height: "165px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}>
                                <Spin indicator={antIcon}
                                      spinning={true}
                                      size={"small"}>
                                </Spin>
                            </div>
                        ) : (
                            <div style={{textAlign: "center"}}>
                                <div>
                                    <Row gutter={8}>
                                        <Col span={8}>Reservation ID:</Col>
                                        <Col span={8}>Source:</Col>
                                        <Col span={8}>Reservation Status:</Col>
                                    </Row>
                                    <Row gutter={8}>
                                        <Col span={8}>{this.props.currentReservation.reservationID}</Col>
                                        <Col span={8}>{this.props.currentReservation.sourceName}</Col>
                                        <Col span={8}>{this.props.currentReservation.status}</Col>
                                    </Row>
                                </div>
                                <div className="guests">
                                    {
                                        this.guestsToAdd()
                                    }
                                </div>
                            </div>
                        )}
                    </Panel>
                </Collapse>
            </Col>
        );
    }
};
